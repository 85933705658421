import type { CustomPrimaryAction } from '@canalplus/generators-mock';
import { createRecord } from '@canalplus/ifc-onecore';
import { DisplayMode } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import type { SecondaryAction } from '@dce-front/hodor-types/modules/action_layout/definitions';
import type { PrimaryActionOnClickOption } from '@dce-front/hodor-types/modules/action_layout/primary/definitions';
import classNames from 'classnames/bind';
import type { JSX, ReactElement, SyntheticEvent } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getOnClickGenerated } from '../../../../../../components/Linker/hooks/helpers/useLinkerHelper';
import Linker from '../../../../../../components/Linker/Linker';
import type { LinkerProps } from '../../../../../../components/Linker/types';
import { PlayerPlatform } from '../../../../../../constants/playerPlatforms';
import { useAppHistory } from '../../../../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../../../../helpers/hooks/useInvariantSelector';
import { handleStreamingExternalService } from '../../../../../../services/ExternalService';
import {
  getFeatureToggleTVoD,
  offerLocationSelector,
  platformSelector,
} from '../../../../../../store/slices/application-selectors';
import { launchPlayerFullScreen } from '../../../../../../store/slices/player-thunk';
import { setDisplayMode } from '../../../../../../store/slices/ui';
import { isNextEpisodeAutoplaySelector } from '../../../../../../store/slices/user-selectors';
import { useDetailDispatch } from '../../../../../../templates/DetailV5/data/provider';
import { setIsFunnelTvodOpened } from '../../../../../../templates/DetailV5/data/store/actions';
import FunnelTvod from '../../../../../FunnelTvod/components';
import DialogModal from '../../../ActionLayout/DialogModal/DialogModal';
import type { getPrimaryActionTypes } from '../../../ActionLayout/helpers';
import styles from './Episode.css';

const cx = classNames.bind(styles);

export type EpisodeProps = {
  /**
   * Takes an `EpisodeMedia` component that displays the episode image.
   */
  media?: ReactElement;
  /**
   * Takes an `EpisodeEditorial` component. Contains the editorial information for an episode.
   */
  editorial?: ReactElement;
  /**
   * Personal Video Recorder secondary action.
   */
  pvr?: SecondaryAction;
  /**
   * Title of the episode.
   */
  ariaLabel?: string;
  /**
   * Content ID of the episode.
   */
  contentID?: string;
  /**
   * Season number
   */
  seasonNumber?: number;
  /**
   * Episode number
   */
  episodeNumber?: number;
  /**
   * Click event handler for the primary action.
   */
  onClick?: CustomPrimaryAction['onClick'];
  /**
   * Types of primary actions available for the episode.
   */
  primaryActionTypes?: Partial<ReturnType<typeof getPrimaryActionTypes>>;
  'data-testid'?: string;
  id?: string;
  ariaLabelledBy?: string;
};

/**
 * DetailV5 Episode component
 *
 * @see {@link https://canal-wiki.canal-plus.com/pages/viewpage.action?spaceKey=2M&title=Primary+Actions Primary Actions docs}
 */
function Episode({
  id,
  contentID,
  seasonNumber,
  episodeNumber,
  onClick,
  pvr,
  media,
  editorial,
  primaryActionTypes: {
    isPlay,
    isTransactional,
    isRental,
    isDeepLink,
    isUnavailable,
    isLiveOnGoing,
  } = {},
  'data-testid': dataTestId,
}: Readonly<EpisodeProps>): JSX.Element {
  const dispatch = useAppDispatch();
  const detailDispatch = useDetailDispatch();
  const history = useAppHistory();
  const isNextEpisodeAutoplay = useSelector(isNextEpisodeAutoplaySelector);
  const isFeatureToggleTVoD = useInvariantSelector(getFeatureToggleTVoD);

  const offerLocation = useInvariantSelector(offerLocationSelector);
  const platform = useSelector(platformSelector);

  const [showModal, setShowModal] = useState(false);
  const [showFunnelModal, setShowFunnelModal] = useState(false);

  const { displayTemplate, options, displayName, URLPage = '' } = onClick || {};
  const isExternalSiteTemplate = displayTemplate === Template.ExternalSite;
  const hasModalOptions = options?.length;

  /**
   * Handles context action dispatches triggered when Tvod is clicked
   */
  const handleFunnelTvodOpenStatus = (isOpened = true) => {
    setShowFunnelModal(isOpened);
    detailDispatch(setIsFunnelTvodOpened(isOpened));
  };

  /**
   * Handles store action dispatches triggered when an Episode is clicked
   */
  const handleDispatchesOnClick = async (event: SyntheticEvent) => {
    if (isPlay && isExternalSiteTemplate) {
      const linkerProps: LinkerProps = { data: { mainOnClick: onClick } };
      const handleClick = getOnClickGenerated(
        linkerProps,
        dispatch,
        offerLocation,
        platform,
        history
      );
      handleClick(event);
      return;
    }
    if (isPlay && onClick) {
      if (!$_BUILD_RENDERMODE_CSR) {
        dispatch(setDisplayMode(DisplayMode.FULLWINDOWED));
      }
      dispatch(
        launchPlayerFullScreen({
          data: onClick,
          type:
            onClick?.templateMode === Template.LiveTv
              ? PlayerPlatform.Live
              : undefined,
          localSettings: {
            params: {
              nextEpisodeAutoplay: isNextEpisodeAutoplay,
              startAt: onClick?.playbackPosition || onClick?.startTime,
              autoplay: true,
            },
          },
          trackingContext: {
            season_number: seasonNumber,
            episode_number: episodeNumber,
          },
        })
      );
    }

    if (isDeepLink && onClick?.URLMedias) {
      await dispatch(
        handleStreamingExternalService(
          onClick?.URLMedias,
          onClick?.channelName,
          onClick?.tvPackID
        )
      );
    }
  };

  /**
   * Handle clicks on an Episode wrapped in a button
   */
  const handleEpisodeClick = async (event: SyntheticEvent) => {
    if (isPlay && (isLiveOnGoing || isRental) && hasModalOptions) {
      setShowModal(true);
      return;
    } else if (isPlay && onClick) {
      setShowModal(false);
    }

    if (isTransactional && !isExternalSiteTemplate) {
      handleFunnelTvodOpenStatus();
    }

    if ($_BUILD_RENDERMODE_CSR && pvr) {
      createRecord({
        contentId: contentID,
        startDate: pvr.startTime ?? 0,
        endDate: pvr.endTime ?? 0,
        epgId: pvr.epgID ?? 0,
      }).catch(console.error);
    }

    await handleDispatchesOnClick(event);
  };

  const episodeMediaStyles = cx('episode__button', {
    'episode__button--unavailable': isUnavailable,
  });

  return (
    <li className={cx('episode')}>
      {onClick && isTransactional && isExternalSiteTemplate ? (
        <Linker
          data={{ mainOnClick: onClick }}
          ariaLabelledBy={`episode-${contentID}-title-id`}
          className={episodeMediaStyles}
          data-testid={dataTestId}
          id={id}
          /* TODO:
          Add disabled={isUnavailable} to props when custom one-nav
          tv-focus data-attribute is enabled to handle:
          "A VENIR" episode that are non interactive but should be focusable on TV
          */
        >
          {media}
        </Linker>
      ) : (
        // @TODO: Waiting refactor of Linker, for now it only uses Linker for Transactional ExternalSite
        <button
          type="button"
          aria-labelledby={`episode-${contentID}-title-id`}
          onClick={handleEpisodeClick}
          className={episodeMediaStyles}
          data-testid={dataTestId}
          id={id}
          /* TODO:
          Add disabled={isUnavailable} to props when custom one-nav
          tv-focus data-attribute is enabled to handle:
          "A VENIR" episode that are non interactive but should be focusable on TV
          */
        >
          {media}
        </button>
      )}
      {editorial}
      {showFunnelModal && isFeatureToggleTVoD && (
        <FunnelTvod url={URLPage} setIsOpen={handleFunnelTvodOpenStatus} />
      )}
      {showModal && (
        <DialogModal
          title={displayName}
          options={options as PrimaryActionOnClickOption[]}
          setIsOpen={setShowModal}
        />
      )}
    </li>
  );
}

export default Episode;
